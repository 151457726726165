<template>
    <div class="changepass">
        <div class="modal fade" id="changepassword" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="maincontantpass">
                            <h5 class="modal-title">Change Password</h5>
                        </div>
                        <div class="btnclose changebtn">
                            <button type="button" class="btncloses" data-bs-dismiss="modal" aria-label="Close" @click="refreshData()">x</button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="newcurrent">
                                <input type="password" class="form-control toggle-password" name="Current Password"
                                    placeholder="Current Password" ref="curr" v-model="oldPassword" />
                                    <div @click="showHidePass('curr')"> 
                                        <div class="passhidimg" v-if="showPass.curr"><img src="@/assets/images/hidepass.svg" /></div>
                                        <div class="passshowimg" v-else><img src="@/assets/images/showpass.svg" /></div>
                                    </div>
                            </div>
                            <p class="textimpo" v-if="showValidationError && oldPassword == ''">Please Enter current paassword.</p>

                            <div class="newone">
                                <input type="password" class="form-control toggle-password newpaass" name="New Password"
                                    placeholder="New Password" ref="new" v-model="newPassword" @blur="checkPassword()"/>
                                    <div @click="showHidePass('new')"> 
                                        <div class="conpassimg" v-if="showPass.new"><img src="@/assets/images/hidepass.svg" /></div>
                                        <div class="passshowimg" v-else><img src="@/assets/images/showpass.svg" /></div>
                                    </div>
                            </div>
                            <p class="textimpo" v-if="showValidationError && newPassword == ''">Please Enter new paassword.</p>
                            <p class="textimpo" v-if="passwordConditionValidation">
                                Password must be of minimum 8 characters and maximum 20 characters. <br />
                                Password must contains alphabets and number. <br />
                            </p>

                            <div class="confirmpaas">
                                <input type="password" class="form-control toggle-password" name="confirm password"
                                    placeholder="Confirm New Password" ref="confirm" v-model="confirmPassword"  />
                                    <div @click="showHidePass('confirm')">
                                        <div class="conpassimg" v-if="showPass.confirm"><img src="@/assets/images/hidepass.svg" /></div>
                                        <div class="passshowimg" v-else><img src="@/assets/images/showpass.svg" /></div>
                                    </div>
                            </div>
                            <p class="textimpo" v-if="showValidationError && confirmPassword == ''">
                                Please confirm new paassword.
                            </p>
                            <p class="textimpo" v-if="passwordValidation">
                                New password and confirm new password dosen't match.
                            </p>

                            <button type="button" class="btnsubmitpass" @click="submitChangePassword()">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";

export default {
    name: 'ChangePassword',
    inject:['recaptcha'],
    data() {
        return {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            showValidationError: false,
            passwordValidation: false,
            passwordConditionValidation: false,
            showPass: {
                curr: false,
                new: false,
                confirm: false
            }

        }
    },
    methods: {
		refreshData() {
			this.oldPassword = '',
            this.newPassword = '',
            this.confirmPassword = ''
		},
        submitChangePassword() {
			this.showValidationError= false
			this.passwordValidation= false
            if (this.oldPassword == '' || this.newPassword == '' || this.confirmPassword == '') {
                this.showValidationError = true;
            } else if (this.newPassword != this.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.callChangePasswordServiceFunction();
            }
        },
		checkPassword() {
			this.passwordConditionValidation = false;
            var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
			if (!regex.test(this.newPassword)) {
                this.passwordConditionValidation = true;

            }
		},
        async callChangePasswordServiceFunction() {
            
            this.loading = true;
            let recaptchaToken = await this.recaptcha();

            let headers = {
                'Authorization':`Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            
            let data = {
                "current_password": this.oldPassword,
                "new_password": this.newPassword,
                "confirm_password": this.confirmPassword,
                "recaptcha_token": recaptchaToken
            };
            api.post(apiName.CHANGE_MY_PASSWORD, data,{headers}).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {

                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal',error[0]);
                }
            });
        },
        showHidePass(ptype) {
            if(this.showPass[ptype]) {
                this.$refs[ptype].type = 'password'
                this.showPass[ptype] = false;
                return
            }
            this.$refs[ptype].type = 'text'
            this.showPass[ptype] = true
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>

<style></style>