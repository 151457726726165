<template>
    <div class="modal fade bonus-point-modal hide-show-filter" id="lang-modal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h3>Language</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-body">
                    <div class="chosse-lang">
                        <select class="form-control">
                            <option>English</option>
                            <option>Hindi</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LanguageModal'

}
</script>

<style></style>