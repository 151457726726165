<template>

    <div class="modal-content" v-if="gameItem">
        <div class="modal-header">
            <h5 class="modal-title">{{ gameItem?.game_name }}</h5>
            <button type="button" class="cmn-close-btn btn-close" data-bs-dismiss="modal" aria-label="Close" ref="close_button"></button>
        </div>
        <div class="modal-body">
            <div class="casino-rule-img">
                <div v-if="!loading" class="game-iframe-sec">
                    <div v-if="this.iFrameUrl" class="iframe-content">
                        <iframe :src="this.iFrameUrl" frameborder="0"
                            style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                            height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                            title="Tr&amp;eacute;ning TEXT"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import { getGameItem } from '@/shared/constants/shared-data';
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'IFrameModal',
    data() {
        return {
            loading: false,
            iFrameUrl: null,
            gameItem: null,
        }
    },
    mounted() {
        this.gameItem = this.getGameItemHere();
        console.log("this.gameItem : ", this.gameItem);
        if (this.gameItem.provider == 'poker') {
            this.getLivePockerUrl()
        }
        else {
            this.getDreamCasinoLoadGames()
        }
    },
    methods: {
        getLivePockerUrl() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.GET_LIVE_POCKER_URL + this.gameItem.game_code + '?dtype=m', {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                        //this.showSuccessModalFunc(response.data.message)
                        // this.closeModal();
                    }

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeModal()
                }
            });
        },

        getDreamCasinoLoadGames() {
            this.loading = true;
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.DREAM_CASINO + this.gameItem.game_code + '/' + this.gameItem.sm_id, {}, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc('URL not found.')
                            this.closeModal();
                        }
                    }

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error In Iframe Modal : ", error);
                    this.showErrorModalFunc(error[0]);
                    this.closeModal()
                }
            });
        },

        closeModal() {
            setTimeout(() => {
                this.$refs.close_button.click();
            }, 400);
        },

        getGameItemHere() {
            return getGameItem();
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>