<template>
        <div class="offcanvas-header offcanvas-footmain">
            <!-- <div class="recent-bank-sec1">
            <h2>Simpleuser#23</h2>
            <p>Last Login: 2023-05-03 17:06:52</p>
         </div> -->
            <button type="button" class="btn-close main-offcanvas d-none" data-bs-dismiss="offcanvas" aria-label="Close" ref="close_modal" ></button>
        </div>
        <div class="offcanvas-body acccount-main">
            <div class="offcanvas-bd-start">
                <div class="header-menu-top">
                    <ul class="header-menu">
                        <li>
                            <div class="sub-item head-main"><span class="dropdowns9 cmn1">{{ userData?.userid }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="sub-item head-main">
                                <div class="menu-details-heading">
                                    <span> <img class="menu-head-imgs" src="@/assets/images/tm-setting-icon.svg" alt=""
                                            loading="lazy" /> Setting</span>
                                </div>
                                <div class="menu-heading-con">
                                    <div class="form-check form-switch m-0 p-0">
                                        <div class="component-Box d-flex">
                                            <div v-if="clickBetCheck && getClickBetSelectedValue()" class="me-2">
                                                <label>{{ getKFormatter(getClickBetSelectedValue()) }}&nbsp;</label>
                                            </div>
                                            <div class="content">
                                                <label class="switch m5">
                                                    <input type="checkbox" v-model="clickBetCheck" @change="betCheckChangeFunc()" />
                                                    <small></small>
                                                    <p class="accoddany">One Click Bet</p>
                                                </label>
                                            </div>
                                            <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="collapse"
                                                data-bs-target="#clickBetValue"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <OneClickBetStake @success-modal="showSuccessModalFunc"></OneClickBetStake>
                        </li>
                        <li>
                            <div class="sub-item cmns1 head-main">
                                <span class="dropdowns3 cmn1"> <img class="menu-head-imgs"
                                        src="@/assets/images/tm-wallet-balance-icon.svg" alt="" loading="lazy" /> Wallet
                                    balance</span><span class="balance-add">₹ {{ userData?.balance }}</span>
                            </div>
                        </li>
                        <li>
                            <div class="sub-item head-main">
                                <span class="dropdowns5 cmn1"><img class="menu-head-imgs"
                                        src="@/assets/images/tm-exposure-icon.svg" alt="" loading="lazy" />
                                    Exposure</span><span class="dropdowns6 cmn1 exposure">₹ {{ userData?.exposure ?
                                        userData?.exposure : 0 }}</span>
                            </div>
                        </li>

                        <li class="sub-item head-main">
                            <div class="info-main">
                                <div class="menu-details-heading">
                                    <h4 class="bonus-info">Bonus Information</h4>
                                </div>
                                <div class="menu-details-heading">
                                    <h4 class="bonusheading-inner">
                                        <span class="information-icon">
                                            <a href="bonus.html"><img class="info-imgg" src="@/assets/images/info-iconn.svg"
                                                    alt="" loading="lazy" /></a>
                                        </span>
                                    </h4>
                                </div>
                            </div>
                        </li>

                        <li>
                            <div class="bonus-sec">
                                <div class="credits-list">
                                    <div class="credits-list-con">
                                        <h5 class="cmn1">Earn Bonus</h5>
                                        <h6 class="cmn1">₹ 8000</h6>
                                    </div>
                                    <div class="credits-list-con locbonus">
                                        <a class="lockk-btn" href="#locked-bonus-modal" data-bs-toggle="modal">
                                            <h5 class="cmn1">Locked Bonus</h5>
                                            <h6 class="cmn1">₹ 400</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="credits-chackn-box1">
                                    <div class="menu-details-heading">
                                        <h4 class="bonus-info">
                                            Welcome Bonus
                                            <span class="information-icon" data-bs-toggle="modal"
                                                data-bs-target="#informationModal"><img class="info-immg"
                                                    src="@/assets/images/info-iconn.svg" alt="" loading="lazy" /></span>
                                        </h4>
                                    </div>
                                    <div class="menu-heading-con welcome-bonus">
                                        <strong class="cmn1">₹ 500</strong>
                                    </div>
                                </div>
                                <div class="credits-chackn-box2">
                                    <div class="menu-details-heading">
                                        <h4 class="bonus-info">Play with Bonus</h4>
                                    </div>
                                    <div class="menu-heading-con">
                                        <div class="form-check form-switch m-0 p-0">
                                            <input class="form-check-input" type="checkbox" id="settingCheckDefaults" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="wallet-balance-sec refer-and-earn-menu">
                                <a href="refer-and-earn.html" class="refer-earn-inner-page">
                                    <div class="menu-details-heading">
                                        <h4 class="bonus-info">
                                            Refer And Earn
                                        </h4>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="head-bottom">
                <ul class="head-bottom-items">
                    <li>
                        <router-link :to="{name: 'my-market'}" class="sub-item cmn-usse" @click="closeModal()">
                            <div class="dropdowns76 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/my-market-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>My Market</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'favourites'}" class="sub-item cmn-usse" @click="closeModal()">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/my-favourite-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Favourites</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="closeModal()" :to="{ name: 'bets', params: { type: 3, cat: 'statements' } }" class="sub-item cmn-usse">
                            <div class="dropdowns9 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/nav-account-statement-icon.svg"
                                        alt="" loading="lazy" />
                                    <figcaption>Statement</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="closeModal()" :to="{ name: 'bets', params: { type: 4, cat: 'profit-loss' } }" class="sub-item cmn-usse">
                            <div class="dropdowns10 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/nav-betting-profit-loss-icon.svg"
                                        alt="" loading="lazy" />
                                    <figcaption>Profit & Loss</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="closeModal()" :to="{ name: 'bets', params: { type: 1, cat: 'open-bets' } }" class="sub-item cmn-usse">
                            <div class="dropdowns7 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/nav-open-bet-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Open Bets</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link @click="closeModal()" :to="{ name: 'bets', params: { type: 2, cat: 'settled-bets' } }" class="sub-item cmn-usse">
                            <div class="dropdowns8 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/settled-betsicon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Settled Bets</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <a class="sub-item cmn-usse" href="#" data-bs-toggle="modal" data-bs-target="#edit-stake-modal">
                            <div class="dropdowns10 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/stack-limit-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Stake Limit</figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a class="sub-item cmn-usse" href="#" data-bs-toggle="modal" data-bs-target="#changepassword">
                            <div class="dropdowns11 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/nav-reset-password-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>
                                        Change password
                                    </figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#lang-modal" id="google_translate_element" data-bs-toggle="modal"
                            class="sub-item cmn-usse">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/languages.svg" alt="" />
                                    <figcaption>Language</figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="Javascript:void(0);" class="sub-item cmn-usse" @click="getWalletGetway()">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/deposit-img-menu.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Deposit</figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="Javascript:void(0);" class="sub-item cmn-usse" @click="getWalletGetway()">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/withdrawal.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Withdraw</figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="Javascript:void(0);" class="sub-item cmn-usse" @click="getWalletGetway()">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/wallet-menu-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Wallet</figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>

                    <li>
                        <router-link :to="{name: 'notifications'}" class="sub-item cmn-usse">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/notification-bell.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Notification</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'refer-earn'}" class="sub-item cmn-usse" @click="closeModal()">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/refer-earn-icon.svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Refer And Earn</figcaption>
                                </figure>
                            </div>
                        </router-link>
                    </li>
                    <li>
                        <a href="Javascript:void(0);" class="sub-item cmn-usse" @click="getRules">
                            <div class="dropdowns66 cmn1cm">
                                <figure class="side-img-parent">
                                    <img class="side-in-page-img" src="@/assets/images/rules-icon .svg" alt=""
                                        loading="lazy" />
                                    <figcaption>Rules</figcaption>
                                </figure>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="logout-btn-side-menu">
                <a class="logout-btn" @click="logoutBtnClick()" href="javascript:void(0);"><span
                        class="dropdowns12 cmn1cm">Logout</span></a>
            </div>
        </div>

    <div class="modal fade edit-stake-modal" id="edit-stake-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <section class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="thm-heading">
                        <label>Edit Stake</label>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="edit-list">
                        <ul>
                            <li><input class="form-control profit-input" type="number" placeholder="+ 100" /></li>
                            <li><input class="form-control profit-input" type="number" placeholder="+ 500" /></li>
                            <li><input class="form-control profit-input" type="number" placeholder="+ 1000" /></li>
                            <li><input class="form-control profit-input" type="number" placeholder="+ 2000" /></li>
                        </ul>
                    </div>
                    <div class="footer-cancel-save">
                        <ul class="custom-row">
                            <li class="custom-col"><button class="thm-but bdr-btn" data-bs-dismiss="modal">Cancel</button>
                            </li>
                            <li class="custom-col"><button class="savebtn thm-but" data-bs-dismiss="modal">Save</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <LockedBonusModal></LockedBonusModal>
    <LanguageModal></LanguageModal>
    <BonusInformationModal></BonusInformationModal>
    <div class="model-rule-main">
        <div class="modal fade" id="rule" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="siteRulesModal">
            <RulesModal :siteRuleData='siteRuleData' />
        </div>
    </div>  
</template>

<script>

import { kFormatter } from '@/shared/utils/formatter';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import { getWalletGetwayServiceCall } from "@/modules/account/services/wallet-service";
import { Modal } from 'bootstrap';

import LockedBonusModal from '@/shared/components/locked-bonus/LockedBonusModal.vue';
import LanguageModal from '@/shared/components/language/LanguageModal.vue';
import BonusInformationModal from '@/shared/components/bonus-information/BonusInformationModal.vue';
import RulesModal from '@/shared/components/rules/RulesModal.vue';
import OneClickBetStake from './OneClickBetStake.vue';
export default {
    name: 'Account',
    data() {
        return {
            userData: null,
            clickBetCheck: false,
            collapseOneClickBet: null,
            siteRuleModal: null,
            siteRuleData: ''
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.clickBetCheck = true;
            this.$refs.open_click_bet_value.click();
        }
        this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
        this.userData = this.$store?.getters?.stateUser;
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        closeModal() {
            this.$refs.close_modal.click();
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setClickBetValue', null);
                this.$refs.open_click_bet_value.click();
            }
        },
        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0].value;
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        logoutBtnClick() {
            this.logoutServiceCall();
        },
        logoutServiceCall() {
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
                            this.$store.dispatch('resetAllState');
                            this.$router.push({ name: 'Home' });
                            location.reload();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getWalletGetway() {
            if(this.siteSettings.business_type == 2) {
                getWalletGetwayServiceCall();
            }
        },
        getRules() {
            this.$store.dispatch('setGlobalLoader', true);
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.siteRuleData = response.data?.data?.rules;
                            this.siteRuleModal.show();
                            // this.$emit('rulesModal');
                            // this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                console.log(error)
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },

    },
    components: {
        LockedBonusModal,
        LanguageModal,
        BonusInformationModal,
        RulesModal,
        OneClickBetStake
    }
}
</script>