const VirtualSportModule = () => import(/* webpackChunkName: "virtual-sport-module" */ './views/Module.vue');
const VirtualSport = () => import(/* webpackChunkName: "virtual-sport" */ './views/VirtualSport.vue');

const VirtualSportRoutes = {
    path: '/',
    component: VirtualSportModule,
    children: [
        {
            path: 'virtual-sport',
            name: 'virtual-sport',
            component: VirtualSport
        }
    ],
}

export default VirtualSportRoutes;