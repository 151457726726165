import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import VueCookies from 'vue3-cookies';



import "./assets/css/bootstrap.min.css";
// import "./assets/css/chat.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import { VueReCaptcha } from 'vue-recaptcha-v3'

// createApp(App).mount('#app')
const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueCookies);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITEKEY,loaderOptions: {autoHideBadge: true} })
app.mount("#app");