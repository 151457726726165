
const WalletGateway = () => import(/* webpackChunkName: "wallet-gateway" */ './views/WalletGateway.vue');
const WalletModule = () => import(/* webpackChunkName: "wallet-module" */ './views/Module.vue');


const WalletRoutes = {
    path: '/',
    component: WalletModule,
    children: [
      {
        path: 'wallet-gateway',
        name: 'wallet-gateway',
        component: WalletGateway,
      },
    ],
}
export default WalletRoutes;