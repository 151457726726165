const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
// const MatchMarket = () => import(/* webpackChunkName: "match-market" */ './views/MatchMarket.vue');
const ProfitLoss = () => import(/* webpackChunkName: "profit-loss" */ './views/ProfitLoss.vue');
const MatkaDetails = () => import(/* webpackChunkName: "profit-loss" */ './views/MatkaDetails.vue');
const LiveCardDetails = () => import(/* webpackChunkName: "profit-loss" */ './views/LiveCardDetails.vue');

const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'bets/:cat/:type',
            name: 'bets',
            component: Bets,
        },
        // {
        //     path: 'match-market/:item',
        //     name: 'match-market',
        //     component: MatchMarket,
        // },
        {
            path: 'profit-loss',
            name: 'profit-loss',
            component: ProfitLoss,
        },
        {
            path: 'matka-details',
            name: 'matka-details',
            component: MatkaDetails,
        },
        {
            path: 'live-card-details',
            name: 'live-card-details',
            component: LiveCardDetails,
        }
    ],
}

export default BetsRoutes;