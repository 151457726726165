<template>
    <div class="modal-dialog modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title" id="exampleModalLabel">Rules</h3>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">x</button>
            </div>
            <div class="modal-body2">
                <div clas="modelpopup-rules" v-html="siteRuleData"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RulesModal',
    props: ['siteRuleData'],
}
</script>

<style></style>