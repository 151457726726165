<template>
    <!-- login up Modal -->
    <div class="login-sign-up">
        <div class="modal fade" id="login" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="form-get">
                    <div class="row g-0">
                        <div class="col-0 col-md-5 col-lg-5">
                            <div class="login-img-bg">
                                <div class="login-img-logo">
                                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo"
                                        alt="logo" loading="lazy" />
                                    </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-7 col-lg-7">
                            <div class="login-close">
                                <button type="button" ref="close_btn" @click="refreshData()" class="close" data-bs-dismiss="modal" aria-label="Close">x</button>
                            </div>
                            <div class="login-now">
                                <div class="login-tab-header">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="cmn-tab-btn nav-item" role="presentation">
                                        <button @click="changeFormType(1)" class="nav-link" :class="form.type == 1 ? 'active': ''" id="mobile-tab" data-bs-toggle="tab" data-bs-target="#mobile" type="button" role="tab" aria-controls="mobile" aria-selected="false">
                                            <img src="@/assets/images/otp.png" />
                                        </button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button @click="changeFormType(2)" class="cmn-tab-btn nav-link" :class="form.type == 2 ? 'active': ''" id="user-tab" data-bs-toggle="tab" data-bs-target="#user" type="button" role="tab" aria-controls="user" aria-selected="true">
                                            <img src="@/assets/images/user-tab.png" />
                                        </button>
                                    </li>
                                </ul>
                                </div>

                                <form id="login">
                                <div class="tab-content" id="myTabContent">
                                    <div class="login-title-header">
                                        <span>Login</span>
                                    </div>
                                    <div class="tab-pane fade" :class="form.type == 1 ? 'show active': ''" id="mobile" role="tabpanel" aria-labelledby="mobile-tab">
                                        <div class="row form-group g-1">
                                            <div class="col-3">
                                                <select v-model="form.code" class="form-select form-control codein" aria-label="Default select example" name="countrycodewhatsapp" id="countrycodewhatsapp">
                                                    <option :value="allowedCountry.phonecode"
                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                        :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                </select>
                                            </div>
                                            <div class="col-9">
                                                <div class="whatsup-sec">
                                                    <div class="input-left">
                                                        <!-- <label>Enter your mobile number</label> -->
                                                        <input type="number" v-model="form.mobileNo" maxlength="10" class="form-control" id="mobile" name="mobile" placeholder="Mobile No." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="showValidationError && getId()==''">
                                                <p  class="wrong-message">Please enter mobile number</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" :class="form.type == 2 ? 'show active': ''" id="user" role="tabpanel" aria-labelledby="user-tab">
                                        <div class="row form-group">
                                            <div class="col-12">
                                                <div class="col-auto">
                                                    <input type="text" v-model="form.userId" class="form-control" placeholder="Username" id="mobile"/>
                                                    <div v-if="showValidationError && getId()==''">
                                                        <p  class="wrong-message">Please enter userId</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row form-group">
                                        <div class="col-12">
                                            <div class="col-auto password-field">
                                                <input type="password" v-model="form.password" class="form-control toggle-password valid" id="password" name="password" aria-invalid="false" placeholder="Your Password" />
                                                <div v-if="showValidationError && form.password==''">
                                                    <p  class="wrong-message">Please enter password.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="mak-gin forgot-password-link" v-if="siteSettings && siteSettings.business_type == 2">
                                        <div class="forpass">
                                        <a href="javascript:void(0);" class="getnewpass" data-bs-toggle="modal" data-bs-target="#getnewpass">Forgot Password?</a>
                                        </div>
                                    </div>

                                    <div class="login-with"> 
                                        <a @click="demoUserLogin()" type="button" id="loginBtn" :class="{ 'btn-loading': demoLoading }" class="btn thm-but">
                                            Demo LogIn
                                            <span><b></b><b></b><b></b></span>
                                        </a>
                                        <a type="button" id="loginBtn" class="btn thm-but" :class="{ 'btn-loading': loading }" @click.prevent="signInMethodCall()">
                                            LogIn
                                            <span><b></b><b></b><b></b></span>
                                        </a>
                                    </div>

                                    <div class="border-back">
                                        <p class="or-login-with">
                                            or Login with</p>
                                    </div>

                                    <div class="mak-gin-main social-icons" v-if="siteSettings?.social_link">
                                        <ul>
                                            <li v-if="siteSettings.social_link.facebook_link">
                                                <a :href="siteSettings.social_link.facebook_link" target="_blank" class="cmn-social-media social-icon-fb">
                                                    <img src="@/assets/images/facebook-logo.svg" alt="" loading="lazy"> 
                                                </a>
                                            </li>
                                            <li v-if="siteSettings.social_link.google_link">
                                                <a :href="siteSettings.social_link.google_link" target="_blank" class="cmn-social-media social-icon-google">
                                                    <img src="@/assets/images/google-logo.svg" alt="" loading="lazy"> 
                                                </a> 
                                            </li>
                                            <li v-if="siteSettings.social_link.instagram_link">
                                                <a :href="siteSettings.social_link.instagram_link" target="_blank" class="cmn-social-media social-icon-ig">
                                                    <img src="@/assets/images/instagram-logo.svg" alt="" loading="lazy"> 
                                                </a> 
                                            </li>
                                            <li v-if="siteSettings.social_link.telegram_link">
                                                <a :href="siteSettings.social_link.telegram_link" target="_blank" class="cmn-social-media social-icon-tg">
                                                    <img src="@/assets/images/telegram-logo.svg" alt="" loading="lazy"> 
                                                </a> 
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="mak-gin-main">
                                        <template v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1">
                                            <div class="mak-gin">
                                                <h3 class="whats-with">Or Continue With <span class="whats-with-now"> Whatsapp</span></h3>
                                            </div>
                                            <div class="button-whatsapp">
                                                <a type="button" class="btn-whatsapp" :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank">
                                                    <img src="@/assets/images/whatsappicon.svg" alt="" loading="lazy" /> <span class="blinking">Whatsapp Now</span>
                                                </a>
                                            </div>
                                        </template>
                                        <div class="mak-gin">
                                            <p class="forpass-in">
                                            Don't have an account?
                                            <a href="#register1" class="join-now" data-bs-toggle="modal" data-bs-target="#register1">Register</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { mapGetters } from 'vuex';
import { Modal } from "bootstrap"

export default {
    name: "Login",
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        })
    },
    inject:['recaptcha'],
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null
        };
    },
    mounted(){
        if(this.$route.name == 'login' && !this.checkIsLogin()){
            let loginModal = new Modal('#login');
            loginModal.show();
        }
        else if(this.$route.name == 'login' && this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: "91",
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
                this.showValidationError = false
        },
        changeFormType(type){
            this.form.type=type
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userid = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, 'demo')
        },
        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        async loginServiceCall(url, type) {
            let id = this.getId();
            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }
            let recaptchaToken = await this.recaptcha();
            let data = {
                "userid": id.toString(),
                "type": this.siteSettings.business_type == 1 ? 2 : this.form.type,
                "password": this.form.password,
                "recaptcha_token": recaptchaToken,
                "cncode": this.form.code,
            };

            api.post(url, data).then(response => {
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                            //for add on all APIs
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            setHeaders();
                            this.setUserDataToStore(userData);
                            this.$store.dispatch('setCaptchaData', null);
                            this.refreshData();
                            //this.$router.go();
                            location.reload();
                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                console.log("Error Login : ", error);
                if (type == 'demo') {
                    this.demoLoading = false;
                } else {
                    this.loading = false;
                }
                this.$store.dispatch('setCaptchaData', null);
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.siteSettings.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                return this.form.userId;
            }
        },
        captchaDataUpdate(item) {
            this.captchaData = item;
            console.log("Captcha Item : ", item);
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
         },
        setUserDataToStore(userData) {
            let user = {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                rid: userData.rid,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                id: userData.id,
                exp: userData.exp,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
            this.$store.dispatch('setEventManage', userData.event_manage);
        }
    },
};
</script>

<style scoped>
div.mm-dropdown ul li {
    display: flex !important;
    padding-left: 10px;
}
</style>