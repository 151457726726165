<template>
	<router-view v-if="$route.name == 'virtual-sport' || $route.name == 'sports-book'" />
	
	<template v-else>
		
		<!-- header section -->
		<Header />
		<!-- sports selection -->
		<SportSelection v-if="checkRoutes()" />

		<router-view  @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"/>

		<MobileFooter></MobileFooter>

		<!-- Login module -->
		<Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></Login>
		<SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></SignUp>
		<ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ForgetPassword>
		<ChangePassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChangePassword>



		<div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
			ref="successmodal" id="successfullyModal">
			<SuccessModal :message="successMsg" />
		</div>

		<div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper"
			id="wrongtoaster">
			<ErrorModal :message="errorMsg" />
		</div>

		
		<GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
		<GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

	</template>
</template>

<script>

import Header from '@/shared/components/header/Header.vue';
import SportSelection from './shared/components/sport-selection/SportSelection.vue';
import Login from "@/modules/authorization/components/Login.vue";
import SignUp from "@/modules/authorization/components/SignUp.vue";
import ForgetPassword from "@/modules/authorization/components/ForgetPassword.vue";
import { mapGetters } from 'vuex';
import { AUTH_TOKEN } from "./shared/constants/cookies-const";
import axios from 'axios';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { setHeaders } from '@/shared/services/headers';
import { callFavouriteApis } from '@/shared/services/common-services';
import moment from 'moment';
import ErrorModal from "@/shared/components/modal/ErrorModal.vue";
import SuccessModal from "@/shared/components/modal/SuccessModal.vue";
import MobileFooter from './shared/components/footer/MobileFooter.vue';
import ChangePassword from './modules/authorization/components/ChangePassword.vue';
import socketService from "./socket/sport-socket-service";
import GamesPop1 from './shared/components/modal/GamesPop1.vue';
import GamesPop2 from './shared/components/modal/GamesPop2.vue';
import { useReCaptcha } from 'vue-recaptcha-v3'
import { provide } from 'vue'

export default {
	name: "App",
	components: {
		Header,
		SportSelection,
		Login,
		SignUp,
		ForgetPassword,
		SuccessModal,
		ErrorModal,
		MobileFooter,
		ChangePassword,
		GamesPop1,
		GamesPop2
	},
	data() {
		return {
			onLine: navigator.onLine,
			showBackOnline: false,
			showSuccessModal: false,
			successMsg: "",
			showErrorModal: false,
			errorMsg: "",
			refreshInit: false,
			scY: 0,
			scTimer: 0,
		}
	},
	setup() {
		const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
		const recaptcha = async (type) => {
			await recaptchaLoaded();
			const token = await executeRecaptcha(type);
			return token;
		};
		provide('recaptcha', recaptcha);
		return {};
	},
	computed: {
		...mapGetters({
			captchaData: 'captchaData', siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections'
		}),
		routePath() {
			return this.$route.name
		}
	},
	mounted() {
		//for use on js pages
		window.store = this.$store;
		window.cookies = this.$cookies;
		window.router = this.$router;
		window.emitLogoutMsg = this.$refs.click_logout_msg
		window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
		//for add on all APIs
		axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
		setHeaders();
		if (this.checkIsLogin()) {
			callFavouriteApis();
		}

		//checking internet connection
		window.addEventListener('online', this.updateOnlineStatus);
		window.addEventListener('offline', this.updateOnlineStatus);
		window.addEventListener('scroll', this.onScroll);
	},
	computed: {
		siteSettings() {
			return this.$store.getters.siteSettings;
		},
		domainName() {
			return this.$store.getters.domainName;
		}
	},
	created() {
		this.getSiteSettingsServiceCall();
		this.socketAllEvents();
	},
	beforeDestroy() {
		window.removeEventListener('online', this.updateOnlineStatus);
		window.removeEventListener('offline', this.updateOnlineStatus);
	},
	methods: {
		setTheme(theme) {
			localStorage.setItem('default_class', theme)
			document.documentElement.className = theme;
		},
		getGlobaLoader() {
			return this.$store.getters.globalLoader;
		},
		getSiteSettingsServiceCall() {
			this.$store.dispatch('setGlobalLoader', true);
			api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(response => {
				this.$store.dispatch('setGlobalLoader', false);
				if (response && response.status == 200) {
					if (response?.data?.status === 0) {
						this.showErrorModalFunc(response.data.debug[0]);
					} else {
						let resData = response?.data?.data
						this.$store.dispatch('setSettings', resData);
						const favicon = document.getElementById("favicon");
						favicon.href = resData.aws_url + resData.storage_path.domain_image + resData.logo;
						const title = document.getElementById("title");
						title.text = resData.domain_name;
						try {
							if (resData.front_css != null && resData.front_css != '') {// Create a style element
								const styleElement = document.createElement('style');
								styleElement.type = 'text/css';
								styleElement.appendChild(document.createTextNode(resData.front_css));

								// Insert the style tag into the head
								document.head.insertAdjacentElement('beforeend', styleElement);

								this.convertCSSToJson(resData.front_css);
								localStorage.setItem('default_class', 'CustomPropertiesTheme');
							}
							else if (localStorage.getItem('default_class')) {
								document.documentElement.className = localStorage.getItem('default_class');
								localStorage.setItem('CustomPropertiesTheme', null);
							}
							else {
								//set default theme
								this.setTheme('purple');
								localStorage.setItem('CustomPropertiesTheme', null);
							}
						}
						catch (error) {
							console.log("ERROR 1: ", error);
						}
						if (this.checkIsLogin())
							this.getWalletBalance();
					}
				}
			}).catch(error => {
				this.$store.dispatch('setGlobalLoader', false);
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		getWalletBalance() {
			let authToken = this.$cookies.get(AUTH_TOKEN);
			if (!authToken) return;
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
			api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
				if (response && response.status == 200 && response.data) {
					let user = this.$store.getters.stateUser;
					user.balance = response.data.bl;
					user.expl = response.data.ex;
					user.bets_count = response.data.bc;
					this.$store.dispatch('setUser', user);
					if ((user.exp - moment().utc().unix()) < 10) {
						this.refreshAuthToken(headers);
					} else {
						setTimeout(function () {
							this.getWalletBalance();
						}.bind(this), 2000);
					}
				} else {
					this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
					this.$store.dispatch('resetAllState');
				}
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
			});
		},
		refreshAuthToken(headers) {
			this.refreshInit = true;
			api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
				let user = this.$store.getters.stateUser;
				user.token = response.data.data.token;
				user.exp = response.data.data.exp;
				this.$cookies.set(AUTH_TOKEN, response.data.data.token);
				this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
				window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
				//for add on all APIs
				axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
				this.$store.dispatch('setUser', user);
				this.refreshInit = false;
				setTimeout(function () {
					this.getWalletBalance();
				}.bind(this), 2000);
			}).catch(error => {
				if (error) {
					this.showErrorModalFunc(error[0]);
				}
				this.refreshInit = false;
			});
		},
		checkForHeaderShow() {
			if (this.routePath == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},
		updateOnlineStatus(e) {
			const {
				type
			} = e;
			this.onLine = type === 'online';
		},
		checkIsLogin() {
			return this.$store.getters.isAuthenticated;
		},

		convertCSSToJson(cssString) {
			const styles = {};
			const customProperties = {};

			// Extract custom properties from :root
			const rootMatch = cssString.match(/:root\s*{([^}]*)}/);
			if (rootMatch) {
				const rootDeclarations = rootMatch[1].split(';').filter(declaration => declaration.trim() !== '' && (declaration.includes('--primary-color') || declaration.includes('--secondary-color')));
				rootDeclarations.forEach(declaration => {
					const [property, value] = declaration.split(':').map(part => part.trim());
					customProperties[property] = value;
				});
			}
			localStorage.setItem('CustomPropertiesTheme', JSON.stringify(customProperties));
		},


		showErrorModalFunc(message) {
			this.errorMsg = message;
			this.showErrorModal = true;
			setTimeout(() => {
				this.showErrorModal = false;
			}, 2000);
		},
		showSuccessModalFunc(message) {
			this.successMsg = message;
			this.showSuccessModal = true;
			setTimeout(() => {
				this.showSuccessModal = false;
			}, 2000);
		},
		goToTop() {
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		},
		onScroll() {
			if (this.scTimer) return;
			this.scTimer = setTimeout(() => {
				this.scY = window.scrollY;
				clearTimeout(this.scTimer);
				this.scTimer = 0;
			}, 100);
		},
		socketAllEvents() {
			if (this.$store.getters.stateUser) {
				socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
				socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

				socketService.on("DepositRequest", (data) => {
					if (data.type == "myId") {
						if (data.notify_type == 1) {
							this.$store.dispatch('setNotification');
							this.showSuccessModalFunc('New Notification...')
						}
					}
				});
				socketService.on("logout", (data) => {
					if (data.type == "logout") {
						// logout user from users
						// if (data.notify_type == 1) {
						// 	this.$store.dispatch('setNotification');
						// 	this.showSuccessModalFunc('New Notification...')
						// }
					}
				});
				socketService.on("banned", (data) => {
					if (data.type == "match_ban") {
						// match ban condition
					} else if (data.type == 'league_ban') {
						// league ban condition
					}
				});

			}
		},
		checkRoutes() {
			let routeName = this.$route.name;
			let notAllowedROute = [
				'bonus',
				'racing-carousel',
				'racing-category',
				'racing-list-details',
				'notifications',
				'refer-earn'
			];
			return !notAllowedROute.includes(routeName);
		},
	},
	watch: {
		onLine(v) {
			if (v) {
				this.showBackOnline = true;
				setTimeout(() => {
					this.showBackOnline = false;
				}, 1000);
			}
		}
	}
};
</script>

