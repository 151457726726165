<template>
    <!-- sign up Modal -->
    <div class="login-sign-up registermain">
         <div class="modal fade" id="register1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-body">
                     <div class="form-get">
                        <div class="row g-0">
                           <div class="col-0 col-md-5 col-lg-5">
                              <div class="login-img-bg">
                                 <div class="login-img-logo">
                                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo" loading="lazy" />
                                 </div>
                              </div>
                           </div>
                           <div class="col-12 col-md-7 col-lg-7">
                              <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">x</button>
                              <div class="login-now">
                                 <h3 class="login-form">Register</h3>

                                 <p v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1" class="socia-heading">Get your ready-made ID from whatsapp</p>
                                 <form id="login">
                                    <a v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1" 
                                        :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank" type="button" class="btn-whatsapp">
                                        <img src="@/assets/images/whatsappicon.svg" alt="" loading="lazy" /> 
                                        <span class="blinking">Whatsapp Now</span>
                                    </a>

                                    <div class="mak-gin text-danger">
                                       <p class="socia-heading">OR create account with Mobile Number</p>
                                    </div>

                                    <div class="number-var mak-gin">
                                       <div class="row g-1">
                                          <div class="col-3">
                                             <select v-model="form.code" class="form-select form-control codein" aria-label="Default select example" name="countrycodewhatsapp" id="countrycodewhatsapp">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                             </select>
                                          </div>

                                          <div class="col-9">
                                             <div class="input-left">
                                                <input type="number" maxlength="10" v-model="form.mobileNo" class="form-control" id="mobile" name="mobile" placeholder="Mobile No." />
                                             </div>
                                          </div>

                                            <div v-if="showErrorMobile && form.mobileNo == ''">
                                                <p class="wrong-message">Please enter mobile number.</p>
                                            </div>  

                                            <div class="col-12">
                                            </div>

                                            <div class="col-12 mak-gin mo-number-right second-timer">
                                                <p>{{ form.mobileNo.length }}/10</p>
                                                <span v-if="timerRunning" style="margin-right: 10px;color: white;">{{
                                                            formatTime(minutes)
                                                            + ':' +
                                                            formatTime(seconds) }}</span>
                                                <button :disabled="loadingOtp || timerRunning" @click="sendOtpCall()" type="button" class="btn cmn-bdr-btn otp-btn">{{ resendOtp ? 'Resend OTP' : 'Get OTP' }}</button>
                                          </div>

                                          <div class="col-12">
                                             <input type="number" v-model="form.otp" class="form-control input-boxes" name="otp" id="otp-input" placeholder="OTP" />
                                             <div class="sending-butn">
                                                <div v-if="showValidationError && getOtp().length < 6">
                                                    <p class="wrong-message">Please enter OTP.</p>
                                                </div>
                                             </div>
                                          </div>

                                          <div class="col-12 mak-gin">
                                             <input type="text" v-model="form.userName" class="form-control" placeholder="User Name" />
                                             <div v-if="showValidationError && form.userName == ''">
                                                <p class="wrong-message">Please enter user name.</p>
                                            </div>
                                          </div>

                                          <div class="col-12 mak-gin">
                                             <input type="password" v-model="form.password" class="form-control toggle-password" name="password" id="password" placeholder="Password" aria-describedby="password" />
                                             <div v-if="showValidationError && form.password == ''" class="mt-2">
                                                <p class="wrong-message">Please enter password.</p>
                                            </div>
                                            <ul class="text-danger mx-3" v-if="passwordValidation">
                                                <li>Password must be of minimum 8 characters and maximum 20 characters </li>
                                                <li>Password must contains alphabets and numbers</li>
                                            </ul>
                                          </div>
                                          <div class="col-12 mak-gin">
                                             <input type="password" v-model="form.confirmPassword" class="form-control toggle-password" name="confirm password" id="password" placeholder="Confirm Password" aria-describedby="password" />
                                             <div class="mt-2">
                                                <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-message">Please enter Confirm password.</p>
                                                <p v-if="passwordValidation && form.password != form.confirmPassword" class="wrong-message">password and confirm passwords are not matched.</p>
                                            </div>
                                          </div>
                                          <div class="col-12">
                                                <Captcha :showValidationError="showErrorMobile" :form="form"
                                                @captcha-data="captchaDataUpdate" />
                                            </div>
                                          <div class="col-12">
                                             <button type="button" class="btn thm-but" :disabled="loadingSignUp" @click="signUpCall()">Register</button>
                                          </div>
                                          <div class="col-12">
                                             <p class="forpass-in">Already have account?<a href="#login" ref="open_login_modal" class="loginregister" data-bs-toggle="modal" data-bs-target="#login"> Log In</a></p>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

</template>

<script>

import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from "bootstrap"

export default {
    name: "SignUp",
    inject:['recaptcha'],

    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings.cncode ? this.siteSettings.cncode : "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    mounted(){
        if(this.$route.name == 'sign-up' && !this.checkIsLogin()){
            let loginModal = new Modal('#register1');
            loginModal.show();
        }
        else if(this.$route.name == 'sign-up' &&this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: "91",
                mobileNo: "",
                otp: [],
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (!this.loadingOtp) {
                if (!this.form.mobileNo) {
                    this.showErrorMobile = true;
                } else {
                    this.sendOtpServiceCall();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let recaptchaToken =  await this.recaptcha();
            api.post(apiName.SEND_OTP, {
                mobile_number: this.form.mobileNo,
                cncode: this.form.code,
                recaptcha_token: recaptchaToken
            }).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.showErrorMobile = false;
                        this.showSuccessModalFunc(response.data.message)
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                this.$store.dispatch('setCaptchaData', null);
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },

        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },

        signUpCall() {
            if (!this.isOtpApiCalled)
                return
            if (!this.loadingSignUp) {
                var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
                let o = this.getOtp();
                this.passwordValidation = false;
                if (!this.form.mobileNo) {
                    this.showErrorMobile = true;
                } else if (o.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                }else if(!regex.test(this.form.password)){
                    this.passwordValidation = true;
                } 
                else if (this.form.password != this.form.confirmPassword) {
                    this.passwordValidation = true;
                } else {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let recaptchaToken = await this.recaptcha();
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "recaptcha_token": recaptchaToken,
                "cncode": this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        } else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$refs.open_login_modal.click();
                           
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                if (error)
                    this.showErrorModalFunc(error[0]);
            });
        },
        captchaDataUpdate(item) {
            this.captchaData = item;
            console.log("Captcha Item : ", item);
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
         },

    },
};
</script>