<template>
    <!-- forgot Modal -->
    <div class="login-sign-up forgetpass">
         <div class="modal fade" id="getnewpass" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
               <div class="modal-content">
                  <div class="modal-body">
                     <div class="form-get">
                        <div class="row g-0">
                           <div class="col-0 col-md-5 col-lg-5">
                              <div class="login-img-bg">
                                 <div class="login-img-logo">
                                    <img :src="siteSettings?.aws_url + siteSettings?.storage_path.domain_image + siteSettings?.logo" alt="logo" loading="lazy" />
                                 </div>
                              </div>
                           </div>

                           <div class="col-12 col-md-7 col-lg-7">
                              <button @click="refreshData()" type="button" class="close" data-bs-dismiss="modal" aria-label="Close">x</button>

                              <div class="login-now">
                                 <h3 class="login-form">Forgot Password ?</h3>
                                 <p>Enter your Phone and we'll send you a otp to get back into your account</p>

                                 <form id="login">
                                    <div class="number-var mak-gin">
                                       <div class="row g-1">
                                          <div class="col-3">
                                             <select v-model="form.code" class="form-select form-control codein" aria-label="Default select example" name="countrycodewhatsapp" id="countrycodewhatsapp">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                             </select>
                                          </div>
                                          <div class="col-9">
                                             <div class="input-left">
                                                <input type="number" maxlength="10" v-model="form.mobileNo" class="form-control" id="mobile" name="mobile" placeholder="Mobile No." />
                                             </div>
                                          </div>

                                          <div v-if="showErrorMobile && form.mobileNo == ''">
                                                <p class="wrong-message">Please enter mobile number.</p>
                                            </div>

                                            
                                            

                                          <div class="col-12 mak-gin mo-number-right second-timer">
                                             <p>{{ form.mobileNo.length }}/10</p>
                                             <span v-if="timerRunning" style="margin-right: 10px;color: white;">{{
                                                        formatTime(minutes)
                                                        + ':' +
                                                        formatTime(seconds) }}</span>
                                             <button :disabled="loadingOtp || timerRunning" @click="sendOtpCall()" type="button" class="btn cmn-bdr-btn otp-btn">{{ resendOtp ? 'Resend OTP' : 'Get OTP' }}</button>
                                          </div>

                                          <div class="col-12">
                                             <input type="number" v-model="form.otp" class="form-control input-boxes" name="otp" id="otp-input" placeholder="OTP" />
                                             <div class="sending-butn">
                                                <div v-if="showValidationError && form.otp == ''" class="mt-2">
                                                    <p class="wrong-message">Please enter OTP.</p>
                                                </div>
                                                <!-- <button type="button" id="resend_time" class="otp-countdown-text text-danger">Resend in <span id="timer"></span></button>
                                                <span id="resend_button" class="otp-countdown-text">
                                                   <button href="#" class="text-success resend-btn float-end d-none" type="button" onclick="sendMessageAgain('resend-forgot-otp')">
                                                      SEND SMS AGAIN
                                                   </button>
                                                </span> -->
                                             </div>
                                          </div>

                                          <div class="col-12 mak-gin">
                                             <input type="password" v-model="form.password" class="form-control toggle-password" name="password" id="password" placeholder="Password"  aria-describedby="password" />
                                                <div v-if="showValidationError && form.password == ''" class="mt-2">
                                                    <p class="wrong-message">Please enter password.</p>
                                                </div>
                                                <ul class="text-danger mx-3" v-if="passwordValidation">
                                                    <li>Password must be of minimum 8 characters and maximum 20 characters </li>
                                                    <li>Password must contains alphabets and numbers</li>
                                                </ul>
                                          </div>
                                          <div class="col-12 mak-gin">
                                             <input type="password" v-model="form.confirmPassword"  class="form-control toggle-password" name="confirm password" id="password" placeholder="Confirm Password"  aria-describedby="password" />
                                                <div>
                                                    <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-message">Please enter
                                                        confirm password.</p>
                                                    <p v-if="passwordValidation && form.password != form.confirmPassword" class="wrong-message">
                                                        Password and confirm password not matched.
                                                    </p>
                                                </div>
                                          </div>
                                          <div class="col-12">
                                                <Captcha :showValidationError="showErrorMobile" :form="form"
                                                @captcha-data="captchaDataUpdate" />
                                            </div>
                                          <div class="col-12">
                                             <button type="button" @click="forgetPasswordCall()" :disabled="loadingUpdate" class="btn thm-but">Done</button>
                                          </div>
                                          <div class="col-12">
                                             <p class="forpass-in">Remember Your Password?<a href="#login" class="loginregister" data-bs-toggle="modal" data-bs-target="#login"> Log In</a></p>
                                          </div>
                                          <div class="create-new-acc">
                                             <a href="#register1" class="create" data-bs-toggle="modal" data-bs-target="#register1">Create New Account</a>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
</template>

<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from "bootstrap"

export default {
    name: "ForgetPassword",
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    inject:['recaptcha'],
    data() {
        return {
            form: {
                code: "91",
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            captchaData: null,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0
        };
    },
    mounted(){
        if(this.$route.name == 'forget-password' && !this.checkIsLogin()){
            let loginModal = new Modal('#getnewpass');
            loginModal.show();
        }
        else if(this.$route.name == 'forget-password' && this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: "91",
                mobileNo: "",
                otp: [],
                password: "",
                confirmPassword: "",
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false
            this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        handleOtpBoxKeyDown(event, index) {
            if (event.key !== "Tab" && event.key !== "ArrowRight" && event.key !== "ArrowLeft") {
                event.preventDefault();
            }
            if (event.key === "Backspace") {
                this.form.otp[index] = null;
                if (index) {
                    this.$refs['otp-box-' + (index - 1)].focus();
                }
                return;
            }
            if ((new RegExp('^([0-9])$')).test(event.key)) {
                this.form.otp[index] = event.key;
                if (index != 5) {
                    this.$refs['otp-box-' + (index + 1)].focus();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let recaptchaToken =await this.recaptcha();
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
                "getUserId": "",
                "recaptcha_token": recaptchaToken
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.$store.dispatch('setCaptchaData', null);
                        this.isOtpApiCalled = true;
                        this.showErrorMobile = false
                        this.showSuccessModalFunc(response.data.message);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                        // this.closeModal();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error) {
                    this.$store.dispatch('setCaptchaData', null);
                    this.showErrorModalFunc(error[0]);
                }
            });
        },

        setResendTimer() {

            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },

        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        captchaDataUpdate(item) {
            this.captchaData = item;
            console.log("Captcha Item : ", item);
        },

        forgetPasswordCall() {
            if (!this.isOtpApiCalled)
                return
            let o = this.getOtp();           
            this.passwordValidation = false;
            var regex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,20}$/;
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }else if(!regex.test(this.form.password)){
                this.passwordValidation = true;
            } 
            else if (o.length != 6 || this.form.password == '' || this.form.confirmPassword == '') {
                this.showValidationError = true;
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.forgotServiceCall();
            }
        },
        async forgotServiceCall() {
            this.loadingUpdate = true;
            let recaptchaToken = await this.recaptcha();
            let data = {
                "phone": this.form.mobileNo,
                "cncode": this.form.code,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),
                "recaptcha_token": recaptchaToken,
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                this.loadingUpdate = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                this.$store.dispatch('setCaptchaData', null);
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
         },

    },
};
</script>