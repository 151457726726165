<template>
	<ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent>
	<div class="messsage-fixed-btn-sec text-align" v-if="scrollHeight">
		<div class="container-fluid">
			<button class="center-bottom-fix-btn" @click="scrollTopFunc"><img src="@/assets/images/up-arrow.png" alt="" /></button>
		</div>
	</div>
	<footer class="game-footer affterlog-main">
		<div class="container-fluid">
			<div class="game-footer-contant">
				<ul>
					<li>
						<a href="Javascript:void(0);" @click="goToMyMarket()" class="side-align last-btn-footer1">
							<figure>
								<img src="@/assets/images/my-market.png" alt="" loading="lazy" />
								<figcaption>
									My Market
								</figcaption>
							</figure>
						</a>
					</li>
					<li>
						<a href="Javascript:void(0);" @click="goToMyGames()" class="last-btn-footer1">
							<figure>
								<img src="@/assets/images/games.png" alt="" loading="lazy" />
								<figcaption>
									Games
								</figcaption>
							</figure>
						</a>
					</li>
					<li>
						<a class="side-align-left last-btn-footer1" @click="openAccountMenu()">
							<figure>
								<img src="@/assets/images/account.png" alt="" loading="lazy" />
								<figcaption>
									Account
								</figcaption>
							</figure>
						</a>
					</li>
				</ul>
			</div>
		</div>
	</footer>

	<button class="btn btn-outline-success d-none" type="button" data-bs-toggle="modal" data-bs-target="#login"
		ref="login_btn">
		Login / Signup
	</button>
	<div class="footer-account offcanvas offcanvas-start" tabindex="-1" id="accountMenu"
		aria-labelledby="offcanvasExamplsLabel" style="visibility:visible">
		<Account @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></Account>
	</div>
</template>

<script>
import { Offcanvas } from 'bootstrap';
import Account from '@/modules/account/components/Account.vue';
import ChatComponent from '@/modules/chat/views/ChatComponent.vue';
export default {
	name: 'MobileFooter',
	components: {
		Account,
		ChatComponent
	},
	data() {
		return {
			accountMenu: null,
			scrollHeight: false
		}
	},
	mounted() {
		if (this.isLogin) {
			this.accountMenu = new Offcanvas('#accountMenu')
		}
		window.addEventListener('scroll', this.handleScroll);
	},
	computed: {
		isLogin() {
			return this.$store.getters.isAuthenticated
		}
	},
	methods: {
		goToMyMarket() {
			if (this.isLogin) {
				this.$router.push({ name: 'my-market' });
			} else {
				this.$refs.login_btn.click();
			}
		},
		goToMyGames() {
			if (this.isLogin) {
				this.$router.push({ name: 'games', params: { type: 'LIVE_CASINO' } });
			} else {
				this.$refs.login_btn.click();
			}
		},
		openAccountMenu() {
			if (this.isLogin) {
				this.accountMenu.toggle();
			} else {
				this.$refs.login_btn.click();
			}
		},
		handleScroll() {
			this.scrollHeight = window.scrollY > 200;
        },
		scrollTopFunc() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
	}
}
</script>