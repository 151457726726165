<template>
   <section class="main-tab">
      <div class="container">
         <div class="main-tab-contant">
            <ul class="nav nav-pills">
               <li class="nav-item" @click="currentTab = 'all'">
                  <router-link to="/" class="nav-link" :class="{ 'active': currentTab == 'all' }">
                     <div class="nav-item-1">
                        <figure>
                           <img src="@/assets/images/inplay1.png" alt="" loading="lazy" />
                           <figcaption>
                              Inplay<br />
                              <span class="game-count">&nbsp;{{ matchCounts?.['inplay'] }}</span>
                           </figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <li class="nav-item" v-for="(sport, n_index) in non_custom_sports" :key="n_index"
                  @click="currentTab = sport.id">
                  <router-link class="nav-link" :to="'/sports/' + sport.slug + '/' + sport.id"
                     :class="{ 'active': currentTab == sport.id }">
                     <div class="nav-item-1">
                        <figure>
                           <img v-if="sport.id == 4" src="@/assets/images/cricket1.png" alt="" loading="lazy" />
                           <img v-if="sport.id == 1" src="@/assets/images/soccer.png" alt="" loading="lazy" />
                           <img v-if="sport.id == 2" src="@/assets/images/tennis.png" alt="" loading="lazy" />
                           <figcaption>
                              {{ sport.name }}<br />
                              <span class="game-count">&nbsp;{{ matchCounts?.[sport.slug] }}</span>
                           </figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <li class="nav-item" @click="currentTab = 'virtual-sports'">
                  <router-link :to="{ name: 'virtual-sport' }" class="nav-link"
                     :class="currentTab == 'virtual-sports' ? 'active' : ''">
                     <div class="nav-item-1">
                        <figure>
                           <img src="@/assets/images/virtual-sports.png" alt="" loading="lazy" />
                           <figcaption>Virtual Sports</figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <li class="nav-item">
                  <a class="nav-link" href="Javascript:void(0);" @click="goToSportsBook()">
                     <div class="nav-item-1">
                        <figure class="sportsbook">
                           <img src=@/assets/images/sportsbook.png alt="" loading="lazy" />
                           <figcaption>
                              Sportsbook<br />
                              <!-- <span class="game-count">5</span> -->
                           </figcaption>
                        </figure>
                     </div>
                  </a>
               </li>
               <li class="nav-item">
                  <router-link :to="{ name: 'games', params: {type: 'LIVE_CASINO'} }" class="nav-link">
                     <div class="nav-item-1">
                        <figure>
                           <img src="@/assets/images/casino.png" alt="" loading="lazy" />
                           <figcaption>Live Casino</figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <li class="nav-item">
                  <router-link :to="{ name: 'games', params: {type: 'WORLD_CASINO'} }" class="nav-link">
                     <div class="nav-item-1">
                        <figure>
                           <img src="@/assets/images/casino.png" alt="" loading="lazy" />
                           <figcaption>World Casino</figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <li class="nav-item">
                  <router-link :to="{name: 'matka'}" class="nav-link" href="#">
                     <div class="nav-item-1">
                        <figure>
                           <img src="@/assets/images/matka-icon.png" alt="" loading="lazy" />
                           <figcaption>Matka</figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <li class="nav-item" v-for="(sport, c_index) in custom_sports" :key="c_index"
                  @click="currentTab = sport.id">
                  <router-link class="nav-link" :to="'/sports/' + sport.slug + '/' + sport.id"
                     :class="{ 'active': currentTab == sport.id }">
                     <div class="nav-item-1">
                        <figure>
                           <img :src="sport.sport_icon" alt="" loading="lazy" />
                           <figcaption>
                              {{ sport.name }}<br />
                              <span class="game-count">{{ matchCounts?.[sport.slug] }}</span>
                           </figcaption>
                        </figure>
                     </div>
                  </router-link>
               </li>
               <!-- <li class="nav-item">
                     <a class="nav-link" href="#">
                        <div class="nav-item-1">
                           <figure>
                              <img src="@/assets/images/horsenrace-icon.png" alt="" loading="lazy"/>
                              <figcaption>Horse race</figcaption>
                           </figure>
                        </div>
                     </a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="#">
                        <div class="nav-item-1">
                           <figure>
                              <img src="@/assets/images/frey-icon.png" alt="" loading="lazy"/>
                              <figcaption>Grey Hound</figcaption>
                           </figure>
                        </div>
                     </a>
                  </li> -->
            </ul>
         </div>
      </div>
   </section>
</template>

<script>
// import ShowMoreSports from '../modal/MobileSportsSelection.vue';
import clickApi from '../../services/click-api';
import * as apiName from '../../services/urls';

export default {
   name: "SportSelection",
   components: {
   },
   data: () => ({
      non_custom_sports: [],
      custom_sports: [],
      clickApiUrl: process.env.VUE_APP_CLICK_API + 'v1/aws-s3/download/',
      type: 'INPLAY',
      currentTab: 'all',
      matchCounts: null 
   }),
   created() {
      this.get_sportsList();
      this.getInplayMatchCounts();
      // if(localStorage.getItem('sportId')){
      //     localStorage.removeItem('sportId')
      // }
   },
   methods: {
      checkInEventMange(type) {
         return this.$store.getters.eventManage ? this.$store.getters.eventManage?.filter(s => s.type == type).length != 0 ? false : true : true;
      },
      get_sportsList() {
         this.loading = true;
         clickApi.get(apiName.GET_SPORTS).then(response => {
            this.loading = false;
            if (response) {
               if (response.status == 200) {
                  var all_sports = response.data.data
                  this.non_custom_sports = []
                  for (var i = 0; i < all_sports.length; i++) {
                     if (all_sports[i].is_custom == 0) {
                        this.non_custom_sports.push(all_sports[i])
                     } else {
                        this.custom_sports.push(all_sports[i])
                     }
                  }
                  this.non_custom_sports.sort((a, b) => {
                     return a.rank - b.rank;
                  });

                  var sport_list = {}
                  sport_list['custom'] = this.custom_sports
                  sport_list['non_custom'] = this.non_custom_sports
                  localStorage.setItem('sports_List', JSON.stringify(sport_list))
               }
            }
         }).catch(error => {
            this.loading = false;
            if (error) {
               this.showErrorModalFunc(error);
            }
         });
      },
      getInplayMatchCounts() {

         clickApi.get(apiName.INPLAY_MATCHES_COUNT).then(response => {
            if (response && response.status == 200) {
               let respData = response.data.data;
               let sportCount = {
                  cricket: 0,
                  soccer: 0,
                  tennis: 0,
                  inplay: 0
               };

               for (const key in respData) {
                  if (key == 4) {
                     sportCount.cricket = respData[key].total
                  } else if (key == 1) {
                     sportCount.soccer = respData[key].total
                  } else if (key == 2) {
                     sportCount.tennis = respData[key].total
                  } else {
                     const sportName = respData[key].sport_name;
                     sportCount[sportName] = respData[key].total;
                  }
                  sportCount.inplay += respData[key].inplay
               }
               this.matchCounts = sportCount;
            }
         }).catch(error => {
            if (error) {
               console.log("Error : ", error);
            }
         });
      },
      goToSportsBook() {
         let isLogin = this.$store.getters.isAuthenticated;
         if(isLogin) {
            this.$router.push({ name: 'sports-book' })
         } else {
            this.showErrorModalFunc('Please login to access Sportsbook!!')      
         }
      },
      showErrorModalFunc(message) {
         this.$emit('error-modal', message);
      },
      showSuccessModalFunc(message) {
         this.$emit('success-modal', message);
      }
   }
}
</script>