<template>
   <header class="header-wrapper" :class="{ bonushead: $route.name == 'bonus' }" v-if="checkRoutes()">
      <div class="container">
         <div class="header-sec">
            <div class="header-nav">
               <div class="back-arrow">
                  <a href="afterloginindex.html"><img src="@/assets/images/back-arrow.svg" alt="" loading="lazy" /></a>
               </div>
               <div class="header-logo">
                  <div class="list-con header-sub-con">
                     <h4 class="text-light">{{ getHeadName() }}</h4>
                  </div>
               </div>
            </div>
            <div class="header-but">
               <a href="deposit-amount.html" class="thm-but">Deposit</a>
               <a href="wallet.html" class="thm-but balance-btn">
                  <span>FP : {{ userData?.balance }}</span>
                  <span class="exp-blance">EXP : {{ userData?.exposure }}</span>
               </a>
            </div>
         </div>
      </div>
   </header>
   <header v-else>
      <nav class="navbar balancebar">
         <div class="container-fluid">
            <div class="logosearch">
               <div class="logo-img">
                  <router-link to="/">
                     <img :src="siteSettings?.aws_url + siteSettings?.storage_path?.domain_image + siteSettings?.logo"
                        alt="logo" loading="lazy" />
                  </router-link>
               </div>
               <div class="searchbar" v-if="checkIsLogin()">
                  <input class="form-control" type="search" placeholder="Search" aria-label="Search" />
                  <div class="searchico"><img src="@/assets/images/search.svg" class="searchicon" alt="" loading="lazy" />
                  </div>
               </div>
            </div>


            <div class="balance-main" v-if="checkIsLogin()">
               <ul class="nav-options">
                  <li>
                     <button type="button" class="btn searchbtn" data-bs-toggle="modal"
                        data-bs-target="#searchbuttonresp"><img src="@/assets/images/search.svg" class="searchicon" alt=""
                           loading="lazy" /></button>
                  </li>
                  <li>
                     <router-link :to="{ name: 'favourites' }" class="nav-optionscmn">
                        Favourites
                     </router-link>
                  </li>
                  <li>
                     <router-link :to="{name: 'my-market'}" class="nav-optionscmn">
                        My Market
                     </router-link>
                  </li>
                  <li>
                     <a class="nav-optionscmn nav-link dropdown-toggle btn" data-bs-toggle="offcanvas"
                        data-bs-target="#accountMenu" role="button" aria-controls="accountMenu">
                        Account
                     </a>
                  </li>
                  <li>
                     <button class="amountwrapper" type="button" @click="getWalletGetway()">
                        <img class="fa-money" src="@/assets/images/moneyyy.png" alt="" loading="lazy" />
                           <span class="balance pe-1">{{ userData?.balance }} </span>
                           <span class="exposure">{{ userData?.exposure }}</span>
                        </button>
                  </li>
               </ul>
            </div>

            <form class="d-flex login-register-btn" v-else>
               <button class="btn btn-outline-success" type="button" data-bs-toggle="modal" data-bs-target="#login">Login</button>
               <button class="btn thm-but" type="button" data-bs-toggle="modal" data-bs-target="#register1">Register</button>               
            </form>

         </div>
      </nav>
   </header>

   <!-- SERACH MODAL -->
   <div class="search-input-model">
      <div class="modal fade" id="searchbuttonresp" tabindex="-1">
         <div class="modal-dialog modal-fullscreen">
            <div class="modal-content">
               <div class="modal-body">
                  <div class="searchbar-main">
                     <div class="searchbarr">
                        <input type="text" placeholder="Search" id="searchbarinput" class="form-control" />
                     </div>
                     <button type="button" class="closebtn-searchh" data-bs-dismiss="modal" aria-label="Close">x</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getWalletGetwayServiceCall } from "@/modules/account/services/wallet-service";

export default {
   name: 'Header',
   data() {
      return {
         showMarquee: true,
         userData: null
      }
   },
   computed: {
      ...mapGetters({
         siteSettings: 'siteSettings', stateUser: 'stateUser'
      })
   },
   mounted() {
      this.userData = this.$store?.getters?.stateUser;
      console.log("Userdata : ", this.userData);
   },
   methods: {
      checkIsLogin() {
         return this.$store.getters.isAuthenticated;
      },
      getHeadName() {
         let routeName = this.$route.name;
         if (routeName == 'bonus') {
            return 'Bonus'
         } else if (routeName == 'racing-carousel' || routeName == 'racing-category' || routeName == 'racing-list-details') {
            let routeParam = this.$route.params.type;
            if (routeParam == 'HORSE_RACE') {
               return 'Horse Race'
            } else if (routeParam == 'GREY_HOUND') {
               return 'Grey Hound'
            }
         } else if (routeName == 'notifications') {
            return 'Notification'
         } else if (routeName == 'refer-earn') {
            return 'Refer & Earn Bonus'
         }
      },
      checkRoutes() {
         let routeName = this.$route.name;
         let allowedROute = [
            'bonus',
            'racing-carousel',
            'racing-category',
            'racing-list-details',
            'notifications',
            'refer-earn'
         ];
         return allowedROute.includes(routeName);
      },
      getWalletGetway() {
         this.$router.push({name: 'wallet-gateway'});
      }
   }
}

</script>