<template>
    <div class="collapse" id="clickBetValue">
        <div class="card card-body">
            <div class="stakeed-sec withdraw-sec bg-transparent p-0 border-0 singlestakeed-sec">
                <div class="thm-gradient-but">
                    <ul v-if="inputvalue">
                        <li><input type="number" @click="selectInputValue(0)"
                                :class="{ 'border-input': inputvalue[0].isSelected }" v-model="inputvalue[0].value"
                                class="btn-thm m-auto">
                        </li>
                        <li><input type="number" @click="selectInputValue(1)"
                                :class="{ 'border-input': inputvalue[1].isSelected }" v-model="inputvalue[1].value"
                                class="btn-thm m-auto">
                        </li>
                        <li><input type="number" @click="selectInputValue(2)"
                                :class="{ 'border-input': inputvalue[2].isSelected }" v-model="inputvalue[2].value"
                                class="btn-thm m-auto">
                        </li>
                        <li><input type="number" @click="selectInputValue(3)"
                                :class="{ 'border-input': inputvalue[3].isSelected }" v-model="inputvalue[3].value"
                                class="btn-thm m-auto">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="header-but  info-footer edit-stake-btn">
                <button ref="close_btn" type="button" class="thm-but thm-bor-but" data-bs-dismiss="modal">Cancel</button>
                <a @click="saveClickBetValue()" class="thm-but ">Save</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ClickBetModal",
    data() {
        return {
            inputvalue: null,
        }
    },
    mounted() {
        if (this.getClickBetValue()) {
            this.inputvalue = this.getClickBetValue();
        }
        else {
            this.inputvalue = [
                {
                    value: 1000,
                    isSelected: true,
                },
                {
                    value: 2000,
                    isSelected: false,
                },
                {
                    value: 3000,
                    isSelected: false,
                },
                {
                    value: 4000,
                    isSelected: false,
                }
            ]
        }
    },
    methods: {
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },

        selectInputValue(index) {
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {
            this.$store.dispatch('setClickBetValue', this.inputvalue);
            this.$emit('success-modal', 'Click bet value updated successfully.');
        }
    },

    emits: ['close', 'success-modal', 'error-modal'],
};
</script>

<style scoped>
.border-input {
    border: 2px solid !important;
}
</style>